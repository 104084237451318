import React from "react";
import { SvgPathBuilder } from "./svg-path-builder";
export function getMidPoint(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
    };
}
var ArrowRenderer = function (props) {
    var startingPoint = props.startingPoint, endingPoint = props.endingPoint, strokeColor = props.strokeColor, strokeWidth = props.strokeWidth;
    // const borderRadius = 10;
    // const midY = getMidPoint(startingPoint, endingPoint).y;
    // const midPoint1 = { x: startingPoint.x, y: midY };
    // const midPoint2 = { x: endingPoint.x, y: midY };
    // const pathString = new SvgPathBuilder()
    //   .moveTo(startingPoint)
    //   // Round corners to the right
    //   .lineTo({ x: midPoint1.x, y: midPoint1.y - borderRadius })
    //   .cubicCurve(
    //     { x: midPoint1.x, y: midPoint1.y },
    //     { x: midPoint1.x, y: midPoint1.y },
    //     {
    //       x: midPoint1.x + borderRadius,
    //       y: midPoint1.y
    //     }
    //   )
    //   // Round corners downwards
    //   .lineTo({ x: midPoint2.x - borderRadius, y: midPoint2.y })
    //   .cubicCurve(
    //     { x: midPoint2.x, y: midPoint2.y },
    //     { x: midPoint2.x, y: midPoint2.y },
    //     {
    //       x: midPoint2.x,
    //       y: midPoint2.y + borderRadius
    //     }
    //   )
    //   .lineTo(endingPoint)
    //   .get();
    var pathString = new SvgPathBuilder()
        .moveTo(startingPoint)
        .cubicCurve({ x: endingPoint.x, y: startingPoint.y }, { x: startingPoint.x, y: endingPoint.y }, endingPoint)
        .get();
    return (<g>
      <path d={pathString} style={{ fill: "none", stroke: strokeColor, strokeWidth: strokeWidth }}/>
    </g>);
};
export default ArrowRenderer;
