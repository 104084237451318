var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from "react";
import { PanelSelectorItem, InputContainer, LeftMenuContainer, ListContainer } from "./styled";
import NodeList from "../node-list";
import { Input } from "../input";
function LeftMenuPanelSelector(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen;
    return (<div style={{
        position: "fixed",
        left: 0,
        top: 0,
        height: "100vh",
        width: 28,
        padding: 0,
        borderRight: "solid 1px rgba(50, 56, 66, 1.00)",
        backgroundColor: "rgb(33, 37, 43)",
        zIndex: 1000
    }}>
      <PanelSelectorItem onClick={function () { return setIsOpen(!isOpen); }} active={isOpen}>
        Add nodes
      </PanelSelectorItem>
    </div>);
}
var LeftMenu = function () {
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var _b = __read(useState(""), 2), search = _b[0], setSearch = _b[1];
    return (<>
      <LeftMenuPanelSelector isOpen={isOpen} setIsOpen={setIsOpen}/>

      <LeftMenuContainer isOpen={isOpen}>
        <InputContainer>
          <Input value={search} onChange={function (e) { return setSearch(e.target.value); }} placeholder="Search for nodes..."/>
        </InputContainer>

        <ListContainer>
          <NodeList filter={search}/>
        </ListContainer>
      </LeftMenuContainer>
    </>);
};
export default LeftMenu;
