var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo } from "react";
import { Store } from "../../controllers/editor-state-controller";
import NodeListItem from "./node-list-item";
import { createHook } from "react-sweet-state";
import Fuse from "fuse.js";
import { List } from "immutable";
var useNodeList = createHook(Store, {
    selector: function (state) { return ({
        nodeDefinitions: state.nodeDefinitions
    }); }
});
var NodeList = function (_a) {
    var filter = _a.filter, addFirst = _a.addFirst;
    var _b = __read(useNodeList(), 2), nodeDefinitions = _b[0].nodeDefinitions, addEntry = _b[1].addEntry;
    var fuse = useMemo(function () {
        var options = {
            shouldSort: true,
            threshold: 1,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["name", "description"]
        };
        return new Fuse(nodeDefinitions.toArray(), options);
    }, [nodeDefinitions]);
    var matchingNodeDefinitions = useMemo(function () {
        if (!filter) {
            return nodeDefinitions;
        }
        return List(fuse.search(filter));
    }, [nodeDefinitions, filter, fuse]);
    useEffect(function () {
        if (!addFirst || !matchingNodeDefinitions.size) {
            return;
        }
        addEntry(matchingNodeDefinitions.first());
    }, [addFirst, matchingNodeDefinitions, addEntry]);
    return (<>
      {matchingNodeDefinitions.map(function (definition) { return (<NodeListItem definition={definition} key={definition.name} onClick={addEntry}/>); })}
    </>);
};
export default NodeList;
