var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import Bluebird from "bluebird";
import React, { useMemo, useRef } from "react";
import { EntryDragContainer } from "../entry-drag-container";
import { Store } from "../../controllers/editor-state-controller";
import { SelectableDraggableEntryView } from "../draggable-entry-view";
import { createHook } from "react-sweet-state";
// @ts-ignore
import { SelectableGroup } from "react-selectable-fast/lib";
var useEntryViews = createHook(Store, {
    selector: function (state) { return ({
        nodeEntries: state.editorState.nodeEntries,
        isDragging: state.isDragging
    }); }
});
var lastDrag = 0;
export function EntryViewContainer() {
    var _this = this;
    var selectableGroup = useRef(null);
    var _a = __read(useEntryViews(), 2), _b = _a[0], nodeEntries = _b.nodeEntries, isDragging = _b.isDragging, moveEntry = _a[1].moveEntry;
    var timedMoveEntry = function (id, x, y) {
        lastDrag = new Date().getTime();
        return moveEntry(id, x, y);
    };
    var onClick = useMemo(function () {
        return function (e) { return __awaiter(_this, void 0, void 0, function () {
            var target, current;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        target = e.target;
                        return [4 /*yield*/, Bluebird.delay(10)];
                    case 1:
                        _a.sent();
                        if (new Date().getTime() - lastDrag < 100 ||
                            !target ||
                            target.className !== "EntityDragContainer") {
                            return [2 /*return*/];
                        }
                        current = selectableGroup.current;
                        if (current) {
                            current.clearSelection();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, [selectableGroup, lastDrag]);
    var onSelectionFinish = useMemo(function () {
        return function (items) {
            console.log(items);
        };
    }, []);
    return (<SelectableGroup allowClickWithoutSelected deselectOnEsc={false} disabled={isDragging} tolerance={0} fixedPosition enableDeselect ref={selectableGroup} onSelectionFinish={onSelectionFinish}>
      <EntryDragContainer onMouseUp={onClick} moveEntry={timedMoveEntry}>
        {nodeEntries
        .valueSeq()
        .map(function (entry) { return (<SelectableDraggableEntryView entry={entry} key={entry.id}/>); })}
      </EntryDragContainer>
    </SelectableGroup>);
}
