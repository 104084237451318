import nodeDirectoryService from "../../services/node-directory-service";
var emptyNode = {
    name: "Empty",
    defaultState: "compact",
    description: "An empty node you can use as a starting point",
    inputsDefinition: {},
    outputsDefinition: {},
    implementationStr: ""
};
nodeDirectoryService.registerDefinition("@wisual/empty", emptyNode);
export default emptyNode;
