var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Store } from "../../../controllers/editor-state-controller";
import React, { useEffect, useRef } from "react";
import { getMidPoint } from "../../arrow-renderer";
import { ConnectableId, Position } from "../../../controllers/editor-state-controller/types";
import InputOutputDot from "../input-output-dot";
import { createHook } from "react-sweet-state";
var useCreateConnection = createHook(Store, {
    selector: function (state) { return ({
        createConnectionState: state.createConnectionState
    }); }
});
function getElementPosition(current) {
    var rect = current.getBoundingClientRect();
    var center = getMidPoint({
        x: rect.left,
        y: rect.top
    }, {
        x: rect.right,
        y: rect.bottom
    });
    return Position(center);
}
export default function EntryViewInputOutput(_a) {
    var typeKey = _a.typeKey, entry = _a.entry, typeDefinition = _a.typeDefinition, isInput = _a.isInput;
    var _b = __read(useCreateConnection(), 2), createConnectionState = _b[0].createConnectionState, actions = _b[1];
    var inputOutputDot = useRef(null);
    useEffect(function () {
        var dotEl = inputOutputDot.current;
        if (!dotEl) {
            return;
        }
        var entryView = dotEl.closest('[data-type="entry-view"]');
        var entryViewRect = entryView && entryView.getBoundingClientRect();
        var entryViewPosition = entryViewRect
            ? Position({
                x: entryViewRect.left,
                y: entryViewRect.top
            })
            : undefined;
        actions.setConnectablePosition(ConnectableId({
            entryId: entry.id,
            isInput: isInput,
            typeId: typeKey
        }), getElementPosition(dotEl), entryViewPosition);
    }, [inputOutputDot, entry, isInput, typeKey, actions]);
    return (<li onMouseOver={function () {
        if (!createConnectionState) {
            return;
        }
        if (!inputOutputDot) {
            return;
        }
        var current = inputOutputDot.current;
        if (!current) {
            return;
        }
        actions.addConnectionAttempt({
            target: getElementPosition(current),
            targetId: entry.id,
            sourceInputOrOutput: typeKey,
            isInput: isInput
        });
    }} onMouseDown={function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!inputOutputDot) {
            return;
        }
        var current = inputOutputDot.current;
        if (!current) {
            return;
        }
        var center = getElementPosition(current);
        actions.startCreateConnection({
            sourcePosition: center,
            sourceId: entry.id,
            sourceInputOrOutput: typeKey,
            isInput: isInput,
            targetAttempt: null
        });
    }} style={{
        position: "relative",
        margin: 0,
        padding: 0,
        color: "rgba(212, 148, 91, 1.00)"
    }}>
      <InputOutputDot entry={entry} onElementRef={inputOutputDot} inputKey={typeKey} top={4} color="rgba(235, 97, 112, 1)" activeColor="rgba(235, 97, 112, 1)" left={isInput ? -16 : undefined} right={isInput ? undefined : -16}/>
      {typeKey}:{" "}
      <span style={{ color: "rgba(210, 110, 226, 1.00)" }}>
        {typeDefinition.typeName}
      </span>
    </li>);
}
