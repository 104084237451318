import React from "react";
import nodeDirectoryService from "../../services/node-directory-service";
var textNode = {
    defaultState: "showAll",
    name: "Text",
    description: "Displays some text on the screen",
    view: function (props) {
        var text = props.inputs.text;
        return <p>{text}</p>;
    },
    inputsDefinition: {
        text: {
            typeName: "string"
        }
    },
    outputsDefinition: {}
};
nodeDirectoryService.registerDefinition("@wisual/text", textNode);
export default textNode;
