var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import React, { useEffect } from "react";
import "react-smooshpack/dist/styles.css";
import path from "path";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor";
import deps from "./dependencies.json";
var files = {
    "/index.js": {
        code: "document.body.innerHTML = `<div>${require('uuid')}</div>`"
    }
};
var globalScope = {};
var mockModuleDirectory = {};
function getModulePath(filepath, target) {
    var isAbsolute = path.isAbsolute(target);
    var isModule = target.charAt(0) !== ".";
    var modulePath = "";
    if (isAbsolute) {
        modulePath = target;
    }
    else if (isModule) {
        modulePath = "/node_modules/" + target;
    }
    else {
        modulePath = path.resolve(path.dirname(filepath), target);
    }
    var filesystem = deps.contents;
    console.log("looking for " + modulePath);
    var contents = filesystem[modulePath];
    if (contents) {
        return modulePath;
    }
    console.log("looking for " + modulePath + ".js");
    contents = filesystem[modulePath + ".js"];
    if (contents) {
        return modulePath + ".js";
    }
    var packageJsonPath = path.resolve(path.dirname(modulePath), "package.json");
    console.log("looking for " + packageJsonPath);
    var packageJson = filesystem[packageJsonPath];
    if (packageJson) {
        var pkg = JSON.parse(packageJson.content);
        if (pkg.main) {
            var mainPath = path.resolve(path.dirname(modulePath), pkg.main);
            console.log("looking for " + mainPath);
            contents = filesystem[mainPath];
            if (contents) {
                return mainPath;
            }
        }
    }
    console.log("looking for " + modulePath + "/index.js");
    contents = filesystem[path.resolve(modulePath, "index.js")];
    if (contents) {
        return path.resolve(modulePath, "index.js");
    }
    console.log("looking for " + modulePath + ".json");
    contents = filesystem[modulePath + ".json"];
    if (contents) {
        return modulePath + ".json";
    }
    throw new Error("Failed to find " + target);
}
function loadModule(modulePath) {
    var contents = deps.contents[modulePath];
    console.log(modulePath, contents);
    var moduleContent = contents.content;
    var moduleStr = "(\n      function evaluate(\n          require,\n          module,\n          exports,\n          setImmediate,\n          global,\n          __dirname,\n          __filename,\n          process\n      ) {\n        'use strict';\n\n        " + moduleContent + "\n\n      });\n  ";
    var moduleFn = eval(moduleStr);
    var context = makeContext(modulePath);
    moduleFn(context.require, context.module, context.exports, context.setImmediate, context.global, context.__dirname, context.__filename, context.process);
    return context.module.exports;
}
function makeRequire(filepath) {
    return function require(target) {
        var modulePath = getModulePath(filepath, target);
        if (mockModuleDirectory[modulePath]) {
            return mockModuleDirectory[modulePath];
        }
        var mod = loadModule(modulePath);
        mockModuleDirectory[modulePath] = mod;
        console.log("loaded", modulePath, mod);
        return mod;
    };
}
function makeContext(filepath) {
    var exports = {};
    return {
        require: makeRequire(filepath),
        module: {
            exports: exports
        },
        exports: exports,
        setImmediate: setImmediate,
        global: globalScope,
        __dirname: path.dirname(filepath),
        __filename: filepath,
        process: {
            env: {}
        }
    };
}
var sw = loadModule("/node_modules/react-sweet-state/lib/cjs/index.js");
console.log(sw);
var dependencies = {
    uuid: "latest"
};
var App = function () {
    useEffect(function () { });
    return (<div style={{ height: "100vh" }}>
      <MonacoEditor width="100vw" height="100vh" language="typescript" editorDidMount={function (editor) { return __awaiter(_this, void 0, void 0, function () {
        var model, workerP, worker;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    model = monaco.editor.createModel("import React from 'react'\nfunction something() { return <div></div>; }", "typescript", monaco.Uri.parse("file:///main.tsx"));
                    editor.setModel(model);
                    Object.keys(deps.contents).map(function (mod) {
                        console.log(mod, deps.contents);
                        monaco.languages.typescript.typescriptDefaults.addExtraLib(deps.contents[mod].content, 'file://' + mod);
                    });
                    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
                        jsx: 1
                    });
                    // @ts-ignore
                    window.monaco = monaco;
                    return [4 /*yield*/, monaco.languages.typescript.getTypeScriptWorker()];
                case 1:
                    workerP = _a.sent();
                    return [4 /*yield*/, workerP("/index.html")];
                case 2:
                    worker = _a.sent();
                    // @ts-ignore
                    window.worker = worker;
                    return [2 /*return*/];
            }
        });
    }); }} theme="vs-dark"/>
    </div>);
};
export default App;
