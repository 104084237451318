var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { createElement, useState } from "react";
import mapValues from "lodash/mapValues";
import { BehaviorSubject, merge, Observable, of, Subject } from "rxjs";
import { componentFromStream } from "recompose";
import { map, scan } from "rxjs/operators";
import { Map } from "immutable";
import log from "loglevel";
import emptyNode from "../../nodes/empty";
import { compileImplementation } from "../../services/typescript-service";
var NodeImplementation = /** @class */ (function () {
    function NodeImplementation(entryId, definition) {
        this.definition = emptyNode;
        this.inputs = {};
        this.outputs = {};
        this.entryViewComponent = null;
        this.subscriptions = Map();
        this.localState$ = new BehaviorSubject(null);
        this.hasSetLocalState = false;
        this.implementationStr = "";
        this.input$ = new Observable();
        this.inputProps$ = new Observable();
        this.entryId = entryId;
        this.loadDefinition(definition);
    }
    NodeImplementation.prototype.loadDefinition = function (definition) {
        this.definition = definition;
        this.inputs = Object.keys(this.definition.inputsDefinition).reduce(function (memo, inputKey) {
            memo[inputKey] = new Subject();
            return memo;
        }, {});
        this.outputs = Object.keys(this.definition.outputsDefinition).reduce(function (memo, outputKey) {
            memo[outputKey] = new Subject();
            return memo;
        }, {});
        this.input$ = this.getInputStream();
        this.entryViewComponent = this.getEntryViewComponent();
    };
    NodeImplementation.prototype.getInputStream = function () {
        var _this = this;
        var inputNames = Object.keys(this.inputs);
        var inputObservables = inputNames.map(function (name) { return _this.inputs[name]; });
        var namedInputs$ = merge.apply(void 0, __spread(inputObservables.map(function (observable, observableIndex) {
            return observable.pipe(map(function (value) { return ({
                value: value,
                name: inputNames[observableIndex]
            }); }));
        })));
        this.inputProps$ = namedInputs$.pipe(scan(function (acc, value) {
            var _a;
            return (__assign({}, acc, (_a = {}, _a[value.name] = value.value, _a)));
        }, {}));
        return namedInputs$.pipe(map(function (input) { return ({
            type: "input",
            value: {
                inputName: input.name,
                inputValue: input.value
            }
        }); }));
    };
    NodeImplementation.prototype.compile = function (implementationStr) {
        if (!implementationStr) {
            return function (input$) { return new Observable(); };
        }
        var code = compileImplementation({ source: implementationStr });
        var result = eval(code);
        return result;
    };
    NodeImplementation.prototype.loadImplementation = function () {
        var _this = this;
        if (!this.definition.implementation && !this.implementationStr) {
            return;
        }
        var implementationFn = this.definition.implementation || this.compile(this.implementationStr);
        if (!implementationFn) {
            return;
        }
        var implementation$ = implementationFn(this.input$, this.localState$);
        this.implementationSubscription = implementation$.subscribe(function (output) {
            if (output.type === "output") {
                var outputValue = output.value;
                var outputSubject = _this.outputs[outputValue.outputName];
                if (outputSubject) {
                    outputSubject.next(outputValue.outputValue);
                }
                else {
                    console.error("Message lost from " + _this.definition.name + " instance. No output named " + outputValue.outputName);
                }
            }
            else if (output.type === "view") {
                // TODO ...
            }
        });
    };
    NodeImplementation.prototype.getEntryViewComponent = function () {
        var _this = this;
        this.loadImplementation();
        if (!this.definition.view) {
            return null;
        }
        var outputSetters = mapValues(this.outputs, function (output) {
            return function (v) { return output.next(v); };
        });
        return componentFromStream(function () {
            return merge(of({}), _this.inputProps$).pipe(map(function (inputs) {
                var view = _this.definition.view;
                if (!view) {
                    return;
                }
                return createElement(view, {
                    inputs: inputs,
                    useState: function (initialState) {
                        _this.setInitialLocalState(initialState);
                        var _a = __read(useState(initialState), 2), value = _a[0], setValue = _a[1];
                        return [
                            value,
                            function (update) {
                                var ret = setValue(update);
                                _this.setLocalState(update);
                                return ret;
                            }
                        ];
                    },
                    outputs: outputSetters,
                    rawOutputs: _this.outputs
                });
            }));
        });
    };
    NodeImplementation.prototype.connect = function (targetImpl, connectionRequest, connectionId) {
        var outputSubject = this.outputs[connectionRequest.sourceOutput];
        var inputSubject = targetImpl.inputs[connectionRequest.targetInput];
        if (!outputSubject || !inputSubject) {
            log.error("Failed to connect", connectionRequest);
            return;
        }
        this.subscriptions = this.subscriptions.set(connectionId, outputSubject.subscribe(inputSubject));
    };
    NodeImplementation.prototype.disconnect = function (connectionId) {
        var subscription = this.subscriptions.get(connectionId);
        if (!subscription) {
            log.error("Failed to disconnect", connectionId);
            return;
        }
        subscription.unsubscribe();
        this.subscriptions = this.subscriptions.delete(connectionId);
    };
    NodeImplementation.prototype.complete = function () {
        Object.values(this.inputs).forEach(function (input) { return input.complete(); });
        Object.values(this.outputs).forEach(function (output) { return output.complete(); });
        if (this.implementationSubscription) {
            this.implementationSubscription.unsubscribe();
        }
    };
    NodeImplementation.prototype.reloadImplementationStr = function (implementationStr) {
        this.implementationStr = implementationStr;
        this.loadImplementation();
    };
    NodeImplementation.prototype.setLocalState = function (obj) {
        this.localState$.next(obj);
    };
    NodeImplementation.prototype.setInitialLocalState = function (obj) {
        if (!this.hasSetLocalState) {
            this.hasSetLocalState = true;
            this.localState$.next(obj);
        }
    };
    return NodeImplementation;
}());
export { NodeImplementation };
