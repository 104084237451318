var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { View } from "./styled";
var EntryViewNodeView = /** @class */ (function (_super) {
    __extends(EntryViewNodeView, _super);
    function EntryViewNodeView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: null,
            errorInfo: null
        };
        return _this;
    }
    EntryViewNodeView.prototype.componentDidCatch = function (error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    };
    EntryViewNodeView.prototype.render = function () {
        var error = this.state.error;
        if (error) {
            return (<View>
          The component has crashed with:
          <pre style={{ maxWidth: 400, maxHeight: 300, overflow: "scroll" }}>
            {error.message}
          </pre>
        </View>);
        }
        return <View>{this.props.children}</View>;
    };
    return EntryViewNodeView;
}(Component));
export default EntryViewNodeView;
