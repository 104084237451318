import React from "react";
import EntryViewInputOutput from "../entry-view-input-output";
export var EntryViewInputs = function (_a) {
    var entry = _a.entry, inputsDefinition = _a.inputsDefinition;
    var inputConnectionPoints = Object.keys(inputsDefinition).map(function (inputKey) {
        var input = inputsDefinition[inputKey];
        return (<EntryViewInputOutput isInput typeKey={inputKey} key={inputKey} typeDefinition={input} entry={entry}/>);
    });
    return (<ul style={{
        padding: "10px",
        listStyle: "none",
        margin: 0,
        borderBottom: "solid 1px rgba(50, 56, 66, 1)"
    }}>
      {inputConnectionPoints}
    </ul>);
};
