var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { useDrop } from "react-dnd";
export var EntryDragContainer = function (_a) {
    var moveEntry = _a.moveEntry, children = _a.children, onMouseUp = _a.onMouseUp;
    var _b = __read(useDrop({
        accept: "NodeEntry",
        drop: function (_a, monitor) {
            var entry = _a.entry;
            var delta = monitor.getDifferenceFromInitialOffset();
            var left = Math.round(entry.position.x + delta.x);
            var top = Math.round(entry.position.y + delta.y);
            moveEntry(entry.id, left, top);
            return undefined;
        }
    }), 2), drop = _b[1];
    return (<div ref={drop} className="EntityDragContainer" onMouseUp={onMouseUp} style={{
        position: "absolute",
        top: 0,
        left: 0,
        flex: 1,
        height: "100vh",
        width: "100vw"
    }}>
      {children}
    </div>);
};
