import React from "react";
import EntryViewInputOutput from "../entry-view-input-output";
export var EntryViewOutputs = function (_a) {
    var entry = _a.entry, outputsDefinition = _a.outputsDefinition;
    var inputConnectionPoints = Object.keys(outputsDefinition).map(function (outputKey) {
        var output = outputsDefinition[outputKey];
        return (<EntryViewInputOutput key={outputKey} typeKey={outputKey} entry={entry} typeDefinition={output} isInput={false}/>);
    });
    return (<ul style={{
        margin: 0,
        listStyle: "none",
        padding: "10px",
        borderBottom: "solid 1px rgba(50, 56, 66, 1)",
        textAlign: "right"
    }}>
      {inputConnectionPoints}
    </ul>);
};
