var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { EntryView } from "../entry-view";
import { EntryContainer, LayerContainer } from "./styled";
import { useActions } from "../../controllers/editor-state-controller";
var getItemProps = function (initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            hide: true,
            x: -1,
            y: -1
        };
    }
    var x = currentOffset.x, y = currentOffset.y;
    return {
        hide: false,
        x: x,
        y: y
    };
};
var EntryViewDragLayer = function () {
    var _a = __read(useActions(), 2), actions = _a[1];
    var _b = useDragLayer(function (monitor) { return ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }); }), isDragging = _b.isDragging, item = _b.item, initialOffset = _b.initialOffset, currentOffset = _b.currentOffset;
    var itemProps = useMemo(function () { return getItemProps(initialOffset, currentOffset); }, [
        initialOffset,
        currentOffset
    ]);
    useEffect(function () {
        if (isDragging) {
            actions.setConnectablesOffset(item.entry.id, currentOffset);
        }
    }, [initialOffset, currentOffset]);
    if (!isDragging) {
        return null;
    }
    return (<LayerContainer>
      <EntryContainer {...itemProps}>
        <EntryView isDragging entry={item.entry}/>
      </EntryContainer>
    </LayerContainer>);
};
export default EntryViewDragLayer;
