var SvgPathBuilder = /** @class */ (function () {
    function SvgPathBuilder() {
        this.operations = [];
    }
    SvgPathBuilder.prototype.moveTo = function (point) {
        this.operations.push({
            type: "move",
            x: point.x,
            y: point.y
        });
        return this;
    };
    SvgPathBuilder.prototype.moveToDelta = function (point) {
        this.operations.push({
            type: "moveToDelta",
            x: point.x,
            y: point.y
        });
        return this;
    };
    SvgPathBuilder.prototype.lineTo = function (point) {
        this.operations.push({
            type: "line",
            x: point.x,
            y: point.y
        });
        return this;
    };
    SvgPathBuilder.prototype.horizontalLineTo = function (x) {
        this.operations.push({
            type: "horizontalLineTo",
            x: x
        });
        return this;
    };
    SvgPathBuilder.prototype.cubicCurve = function (controlPoint1, controlPoint2, endingPoint) {
        this.operations.push({
            type: "cubicCurveTo",
            controlPoint1: controlPoint1,
            controlPoint2: controlPoint2,
            endingPoint: endingPoint
        });
        return this;
    };
    SvgPathBuilder.prototype.get = function () {
        return this.operations
            .map(function (svgPathOperation) {
            return SvgPathBuilder.operationToString(svgPathOperation);
        })
            .join(" ");
    };
    SvgPathBuilder.operationToString = function (operation) {
        switch (operation.type) {
            case "cubicCurveTo":
                return "C" + operation.controlPoint1.x + "," + operation.controlPoint1.y + " " + operation.controlPoint2.x + "," + operation.controlPoint2.y + " " + operation.endingPoint.x + "," + operation.endingPoint.y;
            case "moveToDelta":
                return "m" + operation.x + "," + operation.y;
            case "horizontalLineTo":
                return "H" + operation.x;
            case "move": {
                return "M" + operation.x + "," + operation.y;
            }
            case "line": {
                return "L" + operation.x + "," + operation.y;
            }
            default: {
                return "";
            }
        }
    };
    return SvgPathBuilder;
}());
export { SvgPathBuilder };
