import React from "react";
import nodeDirectoryService from "../../services/node-directory-service";
var sliderNode = {
    name: "Slider",
    defaultState: "compact",
    description: "Sends the state of the slider",
    view: function (props) { return (<input type="range" value={props.inputs.value} onChange={function (e) { return props.outputs.value(e.target.value); }}/>); },
    inputsDefinition: {
        value: {
            typeName: "number"
        }
    },
    outputsDefinition: {
        value: {
            typeName: "number"
        }
    }
};
nodeDirectoryService.registerDefinition('@wisual/slider', sliderNode);
export default sliderNode;
