import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { getRandomKey } from "../../common/get-random-key";
var firebaseConfig = {
    apiKey: "AIzaSyA_uwYdVAWFLJpjcScumvsqh49KIKqQnU4",
    authDomain: "wisual-92926.firebaseapp.com",
    databaseURL: "https://wisual-92926.firebaseio.com",
    projectId: "wisual-92926",
    storageBucket: "wisual-92926.appspot.com",
    messagingSenderId: "1062986078111",
    appId: "1:1062986078111:web:90e2e25293719e80"
};
firebase.initializeApp(firebaseConfig);
export function initializePatch() {
    var key = getRandomKey();
    return firebase
        .database()
        .ref("patches/" + key);
}
export default firebase;
