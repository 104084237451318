import { interval } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import nodeDirectoryService from "../../services/node-directory-service";
var timerNode = {
    name: "Timer",
    defaultState: "compact",
    description: "Trigger a tick every N milliseconds",
    inputsDefinition: {
        interval: {
            typeName: "number"
        }
    },
    outputsDefinition: {
        tick: {
            typeName: "number"
        }
    },
    implementation: function (input$) {
        return input$.pipe(filter(function (input) { return input.type === "input"; }), map(function (input) {
            return input.value;
        }), filter(function (input) { return input.inputName === "interval"; }), switchMap(function (input) {
            return interval(input.inputValue);
        }), map(function (tick) {
            return {
                type: "output",
                value: { outputName: "tick", outputValue: tick }
            };
        }));
    }
};
nodeDirectoryService.registerDefinition("@wisual/timer", timerNode);
export default timerNode;
