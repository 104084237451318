var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from "styled-components";
export var LayerContainer = styled.div({
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
});
export var borderAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    border-color: transparent;\n  }\n  \n  to {\n    border-color: rgba(76, 136, 250, 1);\n  }\n"], ["\n  from {\n    border-color: transparent;\n  }\n  \n  to {\n    border-color: rgba(76, 136, 250, 1);\n  }\n"])));
export var EntryContainer = styled.div.attrs(function (props) { return ({
    style: props.hide
        ? {}
        : {
            transform: "translate3d(" + props.x + "px, " + props.y + "px, 0)"
        }
}); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ",
    ";\n"])), function (props) {
    return props.hide
        ? "display: none;"
        : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          animation: ", " 0.2s ease-out;\n          border: solid 1px rgba(76, 136, 250, 1);\n          border-radius: 3px;\n          position: absolute;\n        "], ["\n          animation: ", " 0.2s ease-out;\n          border: solid 1px rgba(76, 136, 250, 1);\n          border-radius: 3px;\n          position: absolute;\n        "])), borderAnimation);
});
var templateObject_1, templateObject_2, templateObject_3;
