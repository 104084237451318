var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import NodeList from "../node-list";
import { CreateNodeDialogContainer, NodeListContainer } from "./styled";
import { Input } from "../input";
import { Store } from "../../controllers/editor-state-controller";
import { createHook } from "react-sweet-state";
var useCreateNodeState = createHook(Store, {
    selector: function (state) { return ({ createNodeState: state.createNodeState }); }
});
export default function CreateNodeDialog() {
    var _a = __read(useCreateNodeState(), 1), createNodeState = _a[0].createNodeState;
    var _b = __read(useState(""), 2), search = _b[0], setSearch = _b[1];
    var _c = __read(useState(false), 2), addFirst = _c[0], setAddFirst = _c[1];
    useEffect(function () {
        setAddFirst(false);
        setSearch("");
    }, [createNodeState]);
    if (!createNodeState) {
        return null;
    }
    return (<CreateNodeDialogContainer>
      <form onSubmit={function (e) {
        e.preventDefault();
        setAddFirst(true);
    }}>
        <Input autoFocus={true} value={search} onChange={function (e) { return setSearch(e.target.value); }} placeholder="Search for nodes..."/>
      </form>
      <NodeListContainer>
        <NodeList filter={search} addFirst={addFirst}/>
      </NodeListContainer>
    </CreateNodeDialogContainer>);
}
