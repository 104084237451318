import { List, Map } from "immutable";
import { Subject } from "rxjs";
import { createHook, createStore } from "react-sweet-state";
import "../../nodes";
import "./middlewares";
import nodeDirectoryService from "../../services/node-directory-service";
import { EditorState, Position } from "./types";
import { history } from "../../common/history";
import * as actions from "./actions";
export var initialEditorState = new EditorState();
export var initialState = {
    editorState: initialEditorState,
    nodeDefinitions: List(nodeDirectoryService.getDefinitions()),
    connectablePositions: Map(),
    connectableOffsets: Map(),
    createConnectionState: null,
    connections: Map(),
    entryConnections: Map(),
    mousePosition: Position({ x: 0, y: 0 }),
    $editorClickSubject: new Subject(),
    nodeImplementations: {},
    location: history.location,
    createNodeState: null,
    isLoadingPatch: false,
    hasLoadedPatch: false,
    isDragging: false,
    codeEditorStates: Map(),
};
export var Store = createStore({
    initialState: initialState,
    actions: actions
});
export var useActions = createHook(Store, {
    selector: function () { return ({}); }
});
// Set-up history sync
history.listen(function (change) {
    Store.actions.setLocation(change);
});
