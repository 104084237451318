var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { useActions } from "../../../controllers/editor-state-controller";
import { CloseIcon } from "./styled";
function EditIcon(props) {
    return (<div style={{
        position: "absolute",
        cursor: "pointer",
        userSelect: "none",
        color: "rgba(169, 177, 191)",
        right: 10,
        top: 9
    }} onClick={props.onClick}>
      {"</>"}
    </div>);
}
export var EntryViewControls = function (_a) {
    var entryId = _a.entryId, showEditImplementation = _a.showEditImplementation;
    var _b = __read(useActions(), 2), actions = _b[1];
    return (<>
      <CloseIcon onClick={function () { return actions.removeEntry(entryId); }}/>
      {showEditImplementation && <EditIcon onClick={function () { return actions.openEditor(entryId); }}/>}
    </>);
};
