var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var EditorRoot = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: rgba(39, 44, 53, 1);\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n"], ["\n  background-color: rgba(39, 44, 53, 1);\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n"])));
export var EntryViewContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: rgba(32, 37, 44, 1);\n  border-radius: 3px;\n  border: solid 1px rgba(50, 56, 66, 1);\n  box-shadow: 0 0 16px 6px rgba(0, 0, 0, 0.02);\n  min-width: 200px;\n  padding: 0;\n  position: relative;\n"], ["\n  background-color: rgba(32, 37, 44, 1);\n  border-radius: 3px;\n  border: solid 1px rgba(50, 56, 66, 1);\n  box-shadow: 0 0 16px 6px rgba(0, 0, 0, 0.02);\n  min-width: 200px;\n  padding: 0;\n  position: relative;\n"])));
export var EntryViewTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: rgba(164, 172, 186, 1.00);\n  text-align: center;\n"], ["\n  color: rgba(164, 172, 186, 1.00);\n  text-align: center;\n"])));
export var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
