import React from "react";
import { Container, Name, Description } from "./styled";
var NodeListItem = function (_a) {
    var definition = _a.definition, onClick = _a.onClick;
    return (<Container onClick={function () { return onClick(definition); }}>
    <Name>{definition.name}</Name>
    <Description>{definition.description}</Description>
  </Container>);
};
export default NodeListItem;
