var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Record } from "immutable";
export function SRecord(defaultValues, propertyNames, name) {
    if (defaultValues === void 0) { defaultValues = {}; }
    var allDefaultValues = __assign({}, propertyNames.reduce(function (obj, propertyName) {
        obj[propertyName] = null;
        return obj;
    }, {}), defaultValues);
    var R = Record(allDefaultValues, name);
    return R;
}
