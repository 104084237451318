import { Map } from "immutable";
import { keys } from "ts-transformer-keys/index";
import { SRecord } from "../../common/immutable";
export var records = [];
export var Position = SRecord({
    x: 0,
    y: 0,
    z: -1
}, ["x", "y", "z"], "Position");
records.push(Position);
export var Node = SRecord(undefined, ["definition"], "Node");
records.push(Node);
export var Entry = SRecord({
    id: "",
    name: "",
    position: new Position(),
    node: new Node()
}, ["id", "name", "position", "node"], "Entry");
records.push(Entry);
export var Entries = Map;
export var EditorState = SRecord({
    nodeEntries: Entries()
}, ["nodeEntries"], "EditorState");
records.push(EditorState);
export var CreateConnectionTargetAttempt = SRecord({}, ["target", "targetId", "sourceInputOrOutput", "isInput"], "CreateConnectionTargetAttempt");
records.push(CreateConnectionTargetAttempt);
export var CreateConnectionState = SRecord({}, ["sourcePosition", "sourceId", "sourceInputOrOutput", "isInput", "targetAttempt"], "CreateConnectionState");
records.push(CreateConnectionState);
export var CreateNodeState = SRecord({}, ["active"], "CreateNodeState");
records.push(CreateNodeState);
export var ConnectableId = SRecord({}, ["entryId", "isInput", "typeId"], "ConnectableId");
records.push(ConnectableId);
export var CodeEditorState = SRecord({}, ["entryId", "isOpen"], "CodeEditorState");
records.push(CodeEditorState);
export var ConnectionId = SRecord({}, ["sourceId", "targetId", "sourceOutput", "targetInput"], "ConnectionId");
records.push(ConnectionId);
export var Connection = SRecord({}, ["id", "sourceId", "targetId", "sourceOutput", "targetInput"], "Connection");
records.push(Connection);
