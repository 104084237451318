var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import nodeDirectoryService from "../../services/node-directory-service";
import { Input } from "../../components/input";
import { map } from "rxjs/operators";
var MessageNodeView = function (props) {
    var _a = __read(props.useState(""), 2), value = _a[0], setValue = _a[1];
    return <Input value={value} onChange={function (e) { return setValue(e.target.value); }}/>;
};
var messageNode = {
    name: "Message",
    defaultState: "compact",
    description: "Let's users type messages which will be sent by triggering",
    view: MessageNodeView,
    inputsDefinition: {
        send: {
            typeName: "unit"
        }
    },
    outputsDefinition: {
        text: {
            typeName: "string"
        }
    },
    implementation: function (input$, state$) {
        return input$.pipe(map(function (input) { return ({
            type: "output",
            value: {
                outputName: "text",
                outputValue: state$ && state$.getValue()
            }
        }); }));
    }
};
nodeDirectoryService.registerDefinition('@wisual/message', messageNode);
export default messageNode;
