import { Route, Router } from "react-router";
import React from "react";
import { history } from "../common/history";
import Home from "./home";
import PatchEditor from "./patch-editor";
import SandpackDemo from './sandpack-demo';
function Routes() {
    return (<Router history={history}>
      <Route path="/" exact component={Home}/>
      <Route path="/sandpack-demo" exact component={SandpackDemo}/>
      <Route path="/p/:patchId" component={PatchEditor}/>
    </Router>);
}
export default Routes;
