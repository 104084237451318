var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import nodeDirectoryService from '../../services/node-directory-service';
import styled from "styled-components";
var Circle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: solid 3px rgb(196, 206, 222);\n  border-radius: 100%;\n  box-sizing: border-box;\n  height: 10px;\n  width: 10px;\n"], ["\n  border: solid 3px rgb(196, 206, 222);\n  border-radius: 100%;\n  box-sizing: border-box;\n  height: 10px;\n  width: 10px;\n"])));
var bangNode = {
    name: "Bang",
    defaultState: 'showAll',
    description: "Sends an event when clicked",
    view: function (props) {
        return (<button onClick={props.outputs.clicked}>
        <Circle />
      </button>);
    },
    inputsDefinition: {},
    outputsDefinition: {
        clicked: {
            typeName: "unit"
        }
    }
};
nodeDirectoryService.registerDefinition('@wisual/bang', bangNode);
export default bangNode;
var templateObject_1;
