var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import { Entry, Node, Position, ConnectionId, Connection, CreateConnectionState, CreateConnectionTargetAttempt, ConnectableId, CreateNodeState, CodeEditorState } from "./types";
import { NodeImplementation } from "./node-implementation";
import { List } from "immutable";
import uuid from "uuid";
import firebase from "../../services/firebase-service";
import { recordTransit } from "./persistance";
import querystring from "querystring";
import { history } from "../../common/history";
import { omit } from "lodash";
import log from "loglevel";
export var moveEntry = function (id, x, y) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    log.debug("Moving entry " + id + " to (" + x + ", " + y + ")");
    var state = getState();
    setState({
        editorState: state.editorState.setIn(["nodeEntries", id, "position"], new Position({
            x: x,
            y: y
        }))
    });
}; };
export var addEntry = function (definition, e) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    var id = uuid();
    log.debug("Creating new node " + id + " with type " + definition.name);
    var nodeImplementations = state.nodeImplementations;
    nodeImplementations[id] = new NodeImplementation(id, definition);
    setState({
        createNodeState: null,
        editorState: state.editorState.setIn(["nodeEntries", id], new Entry({
            id: id,
            name: definition.name,
            node: new Node({
                definition: definition
            }),
            position: new Position({
                x: e ? e.clientX : window.innerWidth / 2 - 100,
                y: e ? e.clientY : window.innerHeight / 2 - 100
            })
        })),
        nodeImplementations: nodeImplementations
    });
}; };
export function openEditor(entryId) {
    return function (_a) {
        var getState = _a.getState, setState = _a.setState;
        var state = getState();
        var isEditing = state.codeEditorStates.get(entryId);
        setState({
            codeEditorStates: isEditing
                ? state.codeEditorStates.remove(entryId)
                : state.codeEditorStates.set(entryId, CodeEditorState({
                    isOpen: true,
                    entryId: entryId
                }))
        });
    };
}
export var removeEntry = function (entryId) { return function (_a) {
    var getState = _a.getState, setState = _a.setState, actions = _a.actions, dispatch = _a.dispatch;
    var state = getState();
    var nodeImplementations = state.nodeImplementations;
    log.debug("Removing node " + entryId);
    var connectionIds = state.entryConnections.get(entryId, List());
    connectionIds.forEach(function (connectionId) {
        dispatch(actions.removeConnection(connectionId));
    });
    nodeImplementations[entryId].complete();
    delete nodeImplementations[entryId];
    setState({
        isDragging: false,
        editorState: state.editorState.removeIn(["nodeEntries", entryId]),
        entryConnections: state.entryConnections.remove(entryId),
        nodeImplementations: nodeImplementations
    });
}; };
export var addConnection = function (connectionRequest) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    var connectionId = ConnectionId(connectionRequest);
    log.debug("Adding connection at " + connectionId);
    var nodeImplementations = state.nodeImplementations;
    var sourceImpl = nodeImplementations[connectionRequest.sourceId];
    var targetImpl = nodeImplementations[connectionRequest.targetId];
    if (sourceImpl && targetImpl) {
        sourceImpl.connect(targetImpl, connectionRequest, connectionId);
    }
    setState({
        entryConnections: state.entryConnections
            .update(connectionId.sourceId, List(), function (cs) { return cs.push(connectionId); })
            .update(connectionId.targetId, List(), function (cs) { return cs.push(connectionId); }),
        connections: state.connections.set(connectionId, Connection(__assign({ id: connectionId }, connectionRequest)))
    });
}; };
export var removeConnection = function (connectionRequest) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    var connectionId = ConnectionId(connectionRequest);
    var nodeImplementations = state.nodeImplementations;
    var sourceImpl = nodeImplementations[connectionRequest.sourceId];
    var targetImpl = nodeImplementations[connectionRequest.targetId];
    log.debug("Removing connection at " + connectionId);
    if (sourceImpl)
        sourceImpl.disconnect(connectionId);
    if (targetImpl)
        targetImpl.disconnect(connectionId);
    setState({
        connections: state.connections.remove(connectionId),
        entryConnections: state.entryConnections
            .update(connectionId.sourceId, List(), function (cs) {
            return cs.filter(function (c) { return c !== connectionId; });
        })
            .update(connectionId.targetId, List(), function (cs) {
            return cs.filter(function (c) { return c !== connectionId; });
        })
    });
}; };
export var setMousePosition = function (position) { return function (_a) {
    var setState = _a.setState;
    setState({
        mousePosition: position
    });
}; };
export var startCreateConnection = function (sourceCreateConnection) { return function (_a) {
    var setState = _a.setState;
    log.debug("Starting connection creation");
    setState({
        createConnectionState: CreateConnectionState(sourceCreateConnection)
    });
}; };
export var finishCreateConnection = function () { return function (_a) {
    var getState = _a.getState, setState = _a.setState, actions = _a.actions, dispatch = _a.dispatch;
    var state = getState();
    var createConnectionState = state.createConnectionState;
    log.debug("Finishing connection creation");
    if (!createConnectionState || !createConnectionState.targetAttempt) {
        setState({
            createConnectionState: null
        });
        return;
    }
    setState({
        createConnectionState: null
    });
    if (createConnectionState.isInput) {
        dispatch(actions.addConnection({
            sourceId: createConnectionState.targetAttempt.targetId,
            sourceOutput: createConnectionState.targetAttempt.sourceInputOrOutput,
            targetId: createConnectionState.sourceId,
            targetInput: createConnectionState.sourceInputOrOutput
        }));
    }
    else {
        dispatch(actions.addConnection({
            targetId: createConnectionState.targetAttempt.targetId,
            targetInput: createConnectionState.targetAttempt.sourceInputOrOutput,
            sourceId: createConnectionState.sourceId,
            sourceOutput: createConnectionState.sourceInputOrOutput
        }));
    }
}; };
export var addConnectionAttempt = function (attempt) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    var createConnectionState = state.createConnectionState;
    log.debug("Got connection attempt for entry " + attempt.targetId + "::" + attempt.sourceInputOrOutput);
    setState({
        createConnectionState: createConnectionState &&
            createConnectionState.set("targetAttempt", CreateConnectionTargetAttempt(attempt))
    });
}; };
export var setConnectablePosition = function (connectableId, position, parentPosition) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    setState({
        connectablePositions: state.connectablePositions.set(connectableId, position),
        connectableOffsets: parentPosition
            ? state.connectableOffsets.set(connectableId, Position({
                x: position.x - parentPosition.x,
                y: position.y - parentPosition.y
            }))
            : state.connectableOffsets
    });
}; };
export var setConnectablesOffset = function (entryId, currentOffset) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    var state = getState();
    var entry = state.editorState.nodeEntries.get(entryId);
    if (!entry || !currentOffset) {
        return;
    }
    var inputConnectables = Object.keys(entry.node.definition.inputsDefinition).map(function (key) {
        return ConnectableId({
            entryId: entry.id,
            isInput: true,
            typeId: key
        });
    });
    var outputConnectables = Object.keys(entry.node.definition.outputsDefinition).map(function (key) {
        return ConnectableId({
            entryId: entry.id,
            isInput: false,
            typeId: key
        });
    });
    var entryConnectables = inputConnectables.concat(outputConnectables);
    setState({
        connectablePositions: entryConnectables.reduce(function (connectablePositions, connectableId) {
            return connectablePositions.update(connectableId, function () {
                var offset = state.connectableOffsets.get(connectableId) || Position({});
                return Position({
                    x: currentOffset.x + offset.x,
                    y: currentOffset.y + offset.y
                });
            });
        }, state.connectablePositions)
    });
}; };
export var setIsDragging = function (isDragging) { return function (_a) {
    var setState = _a.setState;
    setState({
        isDragging: isDragging
    });
}; };
export var setLocation = function (location) { return function (_a) {
    var setState = _a.setState;
    setState({
        location: location
    });
}; };
export var loadPatch = function (patchKey, search) { return function (_a) {
    var getState = _a.getState, setState = _a.setState;
    return __awaiter(_this, void 0, void 0, function () {
        var state, query, patchRef;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    if (state.hasLoadedPatch) {
                        return [2 /*return*/];
                    }
                    setState({
                        isLoadingPatch: true,
                        hasLoadedPatch: false
                    });
                    if (!patchKey) {
                        return [2 /*return*/];
                    }
                    query = querystring.parse(search.slice(1));
                    return [4 /*yield*/, firebase.database().ref("patches/" + patchKey)];
                case 1:
                    patchRef = _b.sent();
                    log.debug("Loading patch " + patchKey + " from URL", query);
                    if (query.isNew) {
                        log.debug("Patch is new, skipping loading");
                        setState({
                            isLoadingPatch: false,
                            hasLoadedPatch: true
                        });
                        history.replace(__assign({}, state, { search: querystring.stringify(omit(query, "isNew")) }));
                        return [2 /*return*/];
                    }
                    log.debug("Waiting on remote patch data");
                    patchRef.once("value", function (snapshot) {
                        var val = snapshot.val();
                        log.debug("Reading remote patch data", val);
                        if (!val) {
                            setState({
                                hasLoadedPatch: true,
                                isLoadingPatch: false
                            });
                            return;
                        }
                        var sstate = recordTransit.fromJSON(val);
                        var patch = sstate.get("patch");
                        var editorState = patch.get("editorState");
                        var connections = patch.get("connections");
                        var entryConnections = patch.get("entryConnections");
                        var connectablePositions = patch.get("connectablePositions");
                        var newState = __assign({}, state, { editorState: editorState,
                            connections: connections,
                            entryConnections: entryConnections,
                            connectablePositions: connectablePositions, isLoadingPatch: false, hasLoadedPatch: true });
                        log.debug("Loaded data - Updating state", sstate);
                        var nodeImplementations = state.nodeImplementations;
                        newState.editorState.nodeEntries.forEach(function (entry) {
                            var id = entry.id;
                            var definition = state.nodeDefinitions.find(function (def) { return def.name === entry.node.definition.name; });
                            if (!definition) {
                                return;
                            }
                            var nodeImplementation = new NodeImplementation(id, definition);
                            nodeImplementations[id] = nodeImplementation;
                        });
                        newState.editorState.nodeEntries.forEach(function (entry) {
                            var nodeImplementation = nodeImplementations[entry.id];
                            var connectionsIds = newState.entryConnections.get(entry.id, List());
                            connectionsIds.forEach(function (connectionId) {
                                var connection = connections.get(connectionId);
                                if (!connection) {
                                    return;
                                }
                                var targetImplementation = nodeImplementations[connection.targetId];
                                if (!targetImplementation) {
                                    return;
                                }
                                nodeImplementation.connect(targetImplementation, connection, connectionId);
                            });
                        });
                        log.debug("Loaded patch");
                        setState(newState);
                    });
                    return [2 /*return*/];
            }
        });
    });
}; };
export function startCreateNode() {
    return function (_a) {
        var setState = _a.setState;
        setState({
            createNodeState: CreateNodeState({
                active: true
            })
        });
    };
}
export function abortCreateNode() {
    return function (_a) {
        var setState = _a.setState;
        setState({
            createNodeState: null
        });
    };
}
export function setTypeInformationForEntry(entryId, implementationStr, typeInformation) {
    return function (_a) {
        var getState = _a.getState, setState = _a.setState;
        if (typeInformation.result !== "found") {
            return;
        }
        var state = getState();
        setState({
            editorState: state.editorState.updateIn(["nodeEntries", entryId], function (entry) {
                if (typeInformation.functionName) {
                    entry = entry.set("name", typeInformation.functionName.text);
                }
                entry = entry.setIn(["node", "definition", "implementationStr"], implementationStr);
                var nodeImplementation = state.nodeImplementations[entryId];
                if (nodeImplementation) {
                    nodeImplementation.reloadImplementationStr(implementationStr);
                }
                return entry;
            })
        });
    };
}
