var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var LeftMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 28px;\n  z-index: 999;\n  background-color: rgb(33, 37, 43);\n  width: 400px;\n  border-right: solid 1px rgba(50, 56, 66, 1);\n  color: rgba(153, 161, 174, 1);\n  height: 100vh;\n  transform: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  left: 28px;\n  z-index: 999;\n  background-color: rgb(33, 37, 43);\n  width: 400px;\n  border-right: solid 1px rgba(50, 56, 66, 1);\n  color: rgba(153, 161, 174, 1);\n  height: 100vh;\n  transform: ",
    ";\n"])), function (props) {
    return props.isOpen ? "translateX(0px)" : "translateX(-400px)";
});
export var ListContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 10px;\n"], ["\n  padding: 0 10px;\n"])));
export var InputContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 10px;\n"], ["\n  padding: 10px;\n"])));
var panelBg = "rgb(33, 37, 43)";
var panelActiveBg = "rgb(48,55,65)";
export var PanelSelectorItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 10px 0;\n  writing-mode: vertical-rl;\n  box-sizing: border-box;\n  display: flex;\n  transform: rotate(-180deg);\n  color: rgba(153, 161, 174, 1);\n  width: 100%;\n  font-size: 14px;\n  cursor: pointer;\n  border-top: solid 1px rgba(50, 56, 66);\n  align-items: center;\n  justify-content: center;\n  transition: all 0.2s ease-out;\n  user-select: none;\n\n  &:active,\n  &:hover {\n    opacity: 0.8;\n  }\n"], ["\n  background-color: ",
    ";\n  padding: 10px 0;\n  writing-mode: vertical-rl;\n  box-sizing: border-box;\n  display: flex;\n  transform: rotate(-180deg);\n  color: rgba(153, 161, 174, 1);\n  width: 100%;\n  font-size: 14px;\n  cursor: pointer;\n  border-top: solid 1px rgba(50, 56, 66);\n  align-items: center;\n  justify-content: center;\n  transition: all 0.2s ease-out;\n  user-select: none;\n\n  &:active,\n  &:hover {\n    opacity: 0.8;\n  }\n"])), function (props) {
    return props.active ? panelActiveBg : panelBg;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
