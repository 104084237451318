import * as ts from "typescript";
import { JsxEmit } from "typescript";
export function getTypeInformation(entryId, implementationStr) {
    var filePath = entryId + ".tsx";
    var textAst = ts.createSourceFile(filePath, implementationStr, ts.ScriptTarget.Latest);
    var options = { jsx: JsxEmit.React };
    var host = {
        fileExists: function (filePath) { return filePath === filePath; },
        directoryExists: function (dirPath) { return dirPath === "/"; },
        getCurrentDirectory: function () { return "/"; },
        getDirectories: function () { return []; },
        getCanonicalFileName: function (fileName) { return fileName; },
        getNewLine: function () { return "\n"; },
        getDefaultLibFileName: function () { return ""; },
        getSourceFile: function (filePath) { return (filePath === filePath ? textAst : undefined); },
        readFile: function (filePath) {
            return filePath === filePath ? implementationStr : undefined;
        },
        useCaseSensitiveFileNames: function () { return true; },
        writeFile: function () { }
    };
    var program = ts.createProgram({
        options: options,
        rootNames: [filePath],
        host: host
    });
    var checker = program.getTypeChecker();
    var ret = { result: "not-found" };
    ts.forEachChild(textAst, visit);
    return ret;
    /** visit nodes finding exported classes */
    function visit(node) {
        // Only consider exported nodes
        if (!isNodeExported(node)) {
            return;
        }
        if (!ts.isFunctionDeclaration(node)) {
            return;
        }
        var functionType = checker.getTypeAtLocation(node);
        functionType.getCallSignatures().map(function (value) {
            var parameterDecls = value.getDeclaration().parameters;
            var returnType = value.getReturnType();
            var parameters = value.getParameters();
            var parameterTypes = parameterDecls.map(function (decl) {
                return checker.getTypeAtLocation(decl);
            });
            ret = {
                result: "found",
                functionName: node.name,
                parameters: parameters,
                parameterTypes: parameterTypes,
                returnType: returnType
            };
        });
    }
    /** Serialize a symbol into a json object */
    function serializeSymbol(symbol) {
        return {
            name: symbol.getName(),
            documentation: ts.displayPartsToString(symbol.getDocumentationComment(program.getTypeChecker())),
            type: checker.typeToString(checker.getTypeOfSymbolAtLocation(symbol, symbol.valueDeclaration))
        };
    }
    /** Serialize a class symbol information */
    function serializeClass(symbol) {
        var details = serializeSymbol(symbol);
        // Get the construct signatures
        var constructorType = checker.getTypeOfSymbolAtLocation(symbol, symbol.valueDeclaration);
        details.constructors = constructorType
            .getConstructSignatures()
            .map(serializeSignature);
        return details;
    }
    /** Serialize a signature (call or construct) */
    function serializeSignature(signature) {
        return {
            parameters: signature.parameters.map(serializeSymbol),
            returnType: checker.typeToString(signature.getReturnType()),
            documentation: ts.displayPartsToString(signature.getDocumentationComment(program.getTypeChecker()))
        };
    }
    /** True if this is visible outside this file, false otherwise */
    function isNodeExported(node) {
        return ((ts.getCombinedModifierFlags(node) &
            ts.ModifierFlags.ExportDefault) !==
            0 ||
            (!!node.parent && node.parent.kind === ts.SyntaxKind.SourceFile));
    }
}
export function compileImplementation(compilationRequest) {
    // const implementationStr = compilationRequest.source;
    // const filePath = `dummy.tsx`;
    // const textAst = ts.createSourceFile(
    //   filePath,
    //   implementationStr,
    //   ts.ScriptTarget.Latest
    // );
    // const options: ts.CompilerOptions = { jsx: JsxEmit.React };
    // const host: ts.CompilerHost = {
    //   fileExists: filePath => filePath === filePath,
    //   directoryExists: dirPath => dirPath === "/",
    //   getCurrentDirectory: () => "/",
    //   getDirectories: () => [],
    //   getCanonicalFileName: fileName => fileName,
    //   getNewLine: () => "\n",
    //   getDefaultLibFileName: () => "",
    //   getSourceFile: filePath => (filePath === filePath ? textAst : undefined),
    //   readFile: filePath =>
    //     filePath === filePath ? implementationStr : undefined,
    //   useCaseSensitiveFileNames: () => true,
    //   writeFile: () => {}
    // };
    // const program = ts.createProgram({
    //   options,
    //   rootNames: [filePath],
    //   host
    // });
    var code = ts.transpile(compilationRequest.source, {
        jsx: JsxEmit.React
    });
    return code;
}
