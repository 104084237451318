var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { Store } from "../../controllers/editor-state-controller";
import ArrowRenderer from "../arrow-renderer";
import { createHook } from "react-sweet-state";
var useCreateConnectionState = createHook(Store, {
    selector: function (state) { return ({
        createConnectionState: state.createConnectionState,
        mousePosition: state.createConnectionState ? state.mousePosition : null
    }); }
});
var CreateConnectionArrow = function () {
    var _a = __read(useCreateConnectionState(), 1), _b = _a[0], createConnectionState = _b.createConnectionState, mousePosition = _b.mousePosition;
    return (createConnectionState &&
        mousePosition && (<ArrowRenderer startingPoint={createConnectionState.sourcePosition} endingPoint={mousePosition} strokeColor={"rgba(255, 255, 255, 0.5)"} strokeWidth={1}/>));
};
export default CreateConnectionArrow;
