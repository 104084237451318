var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo } from "react";
import { useDrag } from "react-dnd";
// @ts-ignore
import { createSelectable } from "react-selectable-fast/lib";
import { EntryView } from "../entry-view";
import { createHook } from "react-sweet-state";
import { Store } from "../../controllers/editor-state-controller";
var useIsConnecting = createHook(Store, {
    selector: function (state) { return ({
        isConnecting: !!state.createConnectionState
    }); }
});
export var DraggableEntryView = function (_a) {
    var entry = _a.entry, selectableRef = _a.selectableRef, selecting = _a.selecting, selected = _a.selected;
    var _b = __read(useIsConnecting(), 2), isConnecting = _b[0].isConnecting, actions = _b[1];
    var _c = __read(useDrag({
        item: {
            type: "NodeEntry",
            entry: entry
        },
        canDrag: !isConnecting,
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging()
        }); }
    }), 2), isDragging = _c[0].isDragging, drag = _c[1];
    var transform = "translate3d(" + entry.position.x + "px, " + entry.position.y + "px, 0)";
    var containerProps = useMemo(function () { return ({
        onMouseEnter: function () {
            actions.setIsDragging(true);
        },
        onMouseLeave: function () {
            actions.setIsDragging(false);
        },
        style: {
            position: "absolute",
            borderRadius: "3px",
            border: "solid 1px " +
                (selecting || selected ? "rgba(76, 136, 250, 1)" : "transparent"),
            transform: transform
        }
    }); }, [transform, selecting, selected]);
    if (isDragging) {
        return null;
    }
    return (<div {...containerProps} ref={selectableRef}>
      <EntryView drag={drag} entry={entry}/>
    </div>);
};
export var SelectableDraggableEntryView = createSelectable(function (_a) {
    var selectableRef = _a.selectableRef, selected = _a.selected, selecting = _a.selecting, entry = _a.entry;
    return (<DraggableEntryView selected={selected} selecting={selecting} selectableRef={selectableRef} entry={entry}/>);
});
