import transit from "transit-immutable-js";
import { records } from "./types";
import { Map } from "immutable";
import { debounce } from "lodash";
import log from "loglevel";
import firebase from "../../services/firebase-service";
export var recordTransit = transit.withRecords(records);
export function serializePatch(state) {
    var patchKey = state.location.pathname.slice(1);
    return recordTransit.toJSON(Map({
        id: patchKey,
        patch: Map({
            editorState: state.editorState,
            connections: state.connections,
            entryConnections: state.entryConnections,
            connectablePositions: state.connectablePositions
        })
    }));
}
export var saveState = debounce(function (state) {
    if (!state.hasLoadedPatch) {
        return;
    }
    var patchKey = state.location.pathname.slice(1);
    var serializedPatch = serializePatch(state);
    log.debug("Saving state of", patchKey, serializedPatch);
    firebase
        .database()
        .ref("patches/" + patchKey)
        .set(serializedPatch);
}, 20000);
