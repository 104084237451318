var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import ArrowRenderer from "../arrow-renderer";
import { createHook } from "react-sweet-state";
import { Store } from "../../controllers/editor-state-controller";
import { ConnectableId } from "../../controllers/editor-state-controller/types";
var useConnections = createHook(Store, {
    selector: function (state) { return ({
        connections: state.connections,
        connectablePositions: state.connectablePositions
    }); }
});
var ConnectionArrows = function () {
    var _a = __read(useConnections(), 1), _b = _a[0], connections = _b.connections, connectablePositions = _b.connectablePositions;
    return (<>
      {connections.valueSeq().map(function (connection, connectionId) {
        var sourceConnectableId = ConnectableId({
            entryId: connection.sourceId,
            isInput: false,
            typeId: connection.sourceOutput
        });
        var targetConnectableId = ConnectableId({
            entryId: connection.targetId,
            isInput: true,
            typeId: connection.targetInput
        });
        var startingPoint = connectablePositions.get(sourceConnectableId);
        var endingPoint = connectablePositions.get(targetConnectableId);
        if (!startingPoint || !endingPoint) {
            return null;
        }
        return (<ArrowRenderer key={"" + connectionId} startingPoint={startingPoint} endingPoint={endingPoint} strokeColor={"rgba(76, 136, 250, 1)"} strokeWidth={1}/>);
    })}
    </>);
};
export default ConnectionArrows;
