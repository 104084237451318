import styled from "styled-components";
export var CloseIcon = styled.div({
    borderRadius: "100%",
    width: "10px",
    height: "10px",
    transition: "all 0.2s ease-out",
    position: 'absolute',
    top: '14px',
    left: '10px',
    backgroundColor: "rgba(255, 90, 83, 1.00)",
    ":hover": {
        backgroundColor: "rgb(153,54,48)"
    }
});
