var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from "react";
import { EntryViewContainer, EntryViewTitle } from "../styled";
import { EntryViewControls } from "./controls";
import { EntryViewInputs } from "./inputs";
import { EntryViewOutputs } from "./outputs";
import isEmpty from "lodash/isEmpty";
import { Store } from "../../controllers/editor-state-controller";
import { createHook } from "react-sweet-state";
import EntryViewNodeView from "./entry-view-node-view";
import EntryViewCodeEditor from "./entry-view-code-editor";
var useImplementation = createHook(Store, {
    selector: function (state, props) { return ({
        implementation: state.nodeImplementations[props.entry.id],
        isEditing: !!state.codeEditorStates.getIn([props.entry.id, "isOpen"])
    }); }
});
export var EntryView = function (props) {
    var _a = __read(useImplementation(props), 1), _b = _a[0], isEditing = _b.isEditing, implementation = _b.implementation;
    var entry = props.entry;
    var View = implementation && implementation.entryViewComponent
        ? implementation.entryViewComponent
        : null;
    return (<EntryViewContainer data-type="entry-view">
      <div ref={props.drag} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "solid 1px rgba(50, 56, 66, 1)",
        padding: "10px"
    }}>
        <div>
          <EntryViewControls entryId={entry.id} showEditImplementation={entry.node.definition.implementationStr != null}/>
        </div>
        <div style={{ flex: 1 }}>
          <EntryViewTitle>{entry.name}</EntryViewTitle>
        </div>
      </div>

      {isEditing && (<EntryViewCodeEditor width={500} height={500} entryId={entry.id}/>)}

      {!isEmpty(entry.node.definition.inputsDefinition) && (<EntryViewInputs entry={entry} inputsDefinition={entry.node.definition.inputsDefinition}/>)}
      {!isEmpty(entry.node.definition.outputsDefinition) && (<EntryViewOutputs entry={entry} outputsDefinition={entry.node.definition.outputsDefinition}/>)}

      {View &&
        !props.isDragging && (<EntryViewNodeView>
            <View />
          </EntryViewNodeView>)}
    </EntryViewContainer>);
};
