var NodeDirectoryService = /** @class */ (function () {
    function NodeDirectoryService() {
        this.nodeDefinitions = {};
    }
    NodeDirectoryService.prototype.getDefinitions = function () {
        return Object.values(this.nodeDefinitions);
    };
    NodeDirectoryService.prototype.registerDefinition = function (id, definition) {
        this.nodeDefinitions[id] = definition;
    };
    return NodeDirectoryService;
}());
export default new NodeDirectoryService();
