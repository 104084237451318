var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef } from "react";
import Touchbackend from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import { Store } from "../../controllers/editor-state-controller";
import { createHook } from "react-sweet-state";
import HotKeys from "react-hot-keys";
import { EditorRoot } from "../../components/styled";
import EntryViewDragLayer from "../../components/entry-view-drag-layer";
import LeftMenu from "../../components/left-menu";
import { EntryViewContainer } from "../../components/entry-view-container";
import CreateConnectionArrow from "../../components/create-connection-arrow";
import { Position } from "../../controllers/editor-state-controller/types";
import { buffer, debounceTime, filter, map } from "rxjs/operators";
import textNode from "../../nodes/text";
import ConnectionArrows from "../../components/connection-arrows";
import { FullPageSpinner } from "../../components/full-page-spinner";
import CreateNodeDialog from "../../components/create-node-dialog";
var useApp = createHook(Store, {
    selector: function (state) { return ({
        isLoadingPatch: state.isLoadingPatch,
        hasLoadedPatch: state.hasLoadedPatch,
        $editorClickSubject: state.$editorClickSubject
    }); }
});
function PatchEditor(props) {
    var _a = __read(useApp(), 2), state = _a[0], actions = _a[1];
    useEffect(function () {
        actions.loadPatch(props.match.params.patchId, props.location.search);
    }, [actions, props.match, props.location]);
    useEffect(function () {
        var subscription = state.$editorClickSubject
            .pipe(map(function (e) { return ({ clientX: e.clientX, clientY: e.clientY }); }), buffer(state.$editorClickSubject.pipe(debounceTime(500))), filter(function (list) { return list.length >= 2; }))
            .subscribe(function (evts) {
            var evt = evts[evts.length - 1];
            actions.addEntry(textNode, evt);
        });
        return function () { return subscription.unsubscribe(); };
    }, [state.$editorClickSubject, actions]);
    var refEditorRoot = useRef(null);
    if (state.isLoadingPatch || !state.hasLoadedPatch) {
        return <FullPageSpinner />;
    }
    return (<EditorRoot ref={refEditorRoot} onMouseMove={function (e) {
        actions.setMousePosition(Position({
            x: e.clientX,
            y: e.clientY
        }));
    }} onMouseUp={function (e) {
        actions.finishCreateConnection();
        if (e.target === refEditorRoot.current ||
            e.target.className === "EntityDragContainer") {
            state.$editorClickSubject.next(e);
            actions.abortCreateNode();
        }
    }}>
      <DndProvider backend={Touchbackend({
        enableKeyboardEvents: true,
        enableTouchEvents: true,
        enableMouseEvents: true
    })}>
        <HotKeys keyName="space" onKeyDown={function () {
        actions.startCreateNode();
    }}/>
        <HotKeys keyName="esc" onKeyDown={function () {
        actions.abortCreateNode();
    }}/>
        <CreateNodeDialog />
        <LeftMenu />
        <EntryViewDragLayer />
        <EntryViewContainer />

        <svg style={{ width: "100%", height: "100%" }}>
          <CreateConnectionArrow />
          <ConnectionArrows />
        </svg>
      </DndProvider>
    </EditorRoot>);
}
export default PatchEditor;
